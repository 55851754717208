import { useState, useEffect, useContext } from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { ArrowRightSquare } from 'lucide-react'
import Editor from '@uptoo/slate-editor/dist'
import {
  Avatar,
  Button, Drawer, Dropdown, Modal, Spin,
} from 'antd'
import { DownOutlined, PlusCircleOutlined, SendOutlined } from '@ant-design/icons'

import Api from 'services/api'
import classNames from 'utils/classNames'
import getMentions from 'utils/editorMentions/getMentions'
import ModeDropdown from './ModeDropdown'
import Note from './Drawer/note'
import NotesList from './Drawer/list'
import Context from './Context'
import { MentionNotificationsContext } from 'app/contexts/MentionNotifications'

const emptyEditorContent = [{ children: [{ text: '' }], type: 'paragraph' }]

const Notes = ({
  children,
  missionId,
  companyId,
  reload,
  defaultSelected,
  defaultVisible,
  onClose,
}) => {
  const Auth = useSelector((state) => ({
    user: state.Auth.user,
  }))
  const { mentionNotifications, setMentionNotifications } = useContext(MentionNotificationsContext)
  const [visible, setVisible] = useState(defaultVisible || false)
  const [loading, setLoading] = useState(false)
  const [loadingNotes, setLoadingNotes] = useState(false)
  const [noteSelected, setNoteSelected] = useState(null)
  const [notes, setNotes] = useState({ data: [], $pagination: { totalItems: 0 }})
  const [noteToModify, setNoteToModify] = useState(null)
  const [newNote, setNewNote] = useState({})
  const [newComment, setNewComment] = useState(emptyEditorContent)
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [templates, setTemplates] = useState()
  const [firstLoad, setFirstLoad] = useState(true)
  const [autoScroll, setAutoScroll] = useState(null)
  const [content, setContent] = useState()
  const [companyName, setCompanyName] = useState('')
  const [mentionUsers, setMentionUsers] = useState([])
  const [forcedMissionId, setForcedMissionId] = useState('')
  const [mode, setMode] = useState('right')
  const [params, setParams] = useState({
    $filter: {
      _mission: missionId,
      _company: companyId,
    },
    $populate: [
      { path: '_template', select: 'name' },
      { path: '_author', select: 'firstName lastName picture' },
      { path: '_likes', select: 'firstName lastName picture' },
      { path: '_vues', select: 'firstName lastName picture' },
      {
        path: '_comments',
        populate: [
          { path: '_author', select: 'firstName lastName picture' },
          { path: '_likes', select: 'firstName lastName picture' },
        ],
      },

    ],
    $pagination: { itemsPerPage: 5 },
    $sort: { _createdAt: -1 },
  })
  const isList = !noteSelected

  useEffect(() => {
    if (visible && firstLoad) {
      getTemplates()
      getUsers()
      if (defaultSelected && defaultSelected !== 'new') {
        // if (defaultSelected === 'new') {
        //   initNewNote()
        // } else {
        getNote(defaultSelected)
        // }
      }
    }
  }, [visible])

  useEffect(() => {
    if (visible) {
      getNotes()
    }
  }, [params, visible])

  useEffect(() => {
    if (visible) {
      addVue()
    }
  }, [noteSelected, visible])

  useEffect(() => {
    if (visible && autoScroll) {
      const element = document.getElementById(autoScroll)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
      }
      setAutoScroll(null)
    }
  }, [autoScroll])

  useEffect(() => {
    if (visible && noteSelected?._id && !autoScroll) {
      setAutoScroll(noteSelected?._id)
    }
  }, [noteSelected])

  useEffect(() => {
    if (visible && companyId) {
      getCompanyName()
    }
  }, [companyId])

  const getCompanyName = async () => {
    try {
      setLoading(true)
      const { company: t } = await Api.axios.get(`v4/admin/companies/${companyId}`)
      setCompanyName(t.name)
    } finally {
      setLoading(false)
    }
  }

  const getTemplates = async () => {
    try {
      setLoading(true)
      const { templates: t } = await Api.axios.get('v4/admin/templates', {
        params: { $filter: { tags: { $in: ['notes']}}},
      })
      t.sort((a) => (['Point Client', 'Point candidat'].includes(a.name) ? -1 : 1))
      setTemplates(t)
    } finally {
      setLoading(false)
    }
  }

  const getUsers = async () => {
    try {
      setLoading(true)
      const { persons: t } = await Api.axios.get('v4/admin/people', {
        params: {
          $filter: { type: { $in: ['admin', 'developer']}, isActive: true },
          $select: 'firstName lastName',
          $pagination: { itemsPerPage: 300 },
        },
      })
      setMentionUsers(t)
    } finally {
      setLoading(false)
    }
  }

  const getNotes = async (options = {}) => {
    try {
      if (!options.noLoading) {
        setLoadingNotes(true)
      }
      const { notes: data, $pagination } = await Api.axios.get('/v4/admin/notes', { params })
      // let notesToSet = [...data]
      // if (newNote._id && !options.isCreation) {
      //   notesToSet = [newNote, ...notesToSet]
      // }
      if (defaultSelected === 'new' && firstLoad) {
        initNewNote(data)
      } else {
        setNotes({ data, $pagination })
      }
      setFirstLoad(false)
    } finally {
      if (!options.noLoading) {
        setLoadingNotes(false)
      }
    }
  }

  const getNote = async (noteId) => {
    try {
      setLoading(true)
      const { note } = await Api.axios.get(`/v4/admin/notes/${noteId}`, { params })
      setNoteSelected(note)
      setNoteToModify(null)
      setContent(note.content)
    } finally {
      setLoading(false)
    }
  }

  const updateNote = async (noteId, values, options = {}) => {
    try {
      if (!options.noLoading) {
        setLoading(true)
      }
      await Api.axios.put(`/v4/admin/notes/${noteId}${options.noMessage ? '?noMessage=true' : ''}`, values)
      if (!options.noGet) {
        await getNotes(options)
      }
      await getNote(noteId)
    } finally {
      if (!options.noLoading) {
        setLoading(false)
      }
    }
  }

  const deleteNote = async () => {
    try {
      setLoading(true)
      await Api.axios.delete(`/v4/admin/notes/${noteSelected?._id}?deleteComments=true`)
      await getNotes()
      await reload?.()
      setVisibleDelete(false)
      setNoteSelected(null)
    } finally {
      setLoading(false)
    }
  }

  const addVue = async () => {
    if (visible && noteSelected) {
      if (noteSelected.type !== 'new' && !noteSelected._vues?.some((vue) => vue._id === Auth.user._id) &&
    Auth.user._id !== noteSelected._author?._id) {
        await updateNote(
          noteSelected._id,
          { _vues: [...noteSelected._vues?.map((vue) => vue._id) || [], Auth.user._id]},
          { noMessage: true, noGet: true, noLoading: true },
        )
        setNoteSelected((prev) => ({ ...prev, _vues: [...prev._vues, Auth.user]}))
      }
      if (mentionNotifications) {
        const notif = mentionNotifications.find((m) => !!m.noteId && m.noteId === noteSelected._id)
        if (notif) {
          const { unreadNotifications } = await Api.axios.put(`/v4/admin/notifications/${notif._id}`, { isRead: true, _readAt: new Date() })
          setMentionNotifications(unreadNotifications)
        }
      }
    }
  }

  const createComment = async () => {
    try {
      setLoading(true)
      const mentions = getMentions(newComment)
      await Api.axios.post('/v4/admin/comments', {
        content: newComment, _note: noteSelected?._id, _author: Auth.user?._id, mentions,
      })
      setNewComment(emptyEditorContent)
      await getNotes()
      await getNote(noteSelected?._id)
    } finally {
      setLoading(false)
    }
  }

  const initNewNote = async (prevNotes) => {
    // const id = dayjs().valueOf()
    const newItem = {
      // _id: id,
      _mission: missionId,
      _company: companyId,
      _likes: [],
      _author: Auth.user._id,
      // type: 'new',
      // content: [],
    }

    const { note } = await Api.axios.post('/v4/admin/notes', newItem)

    note._templates = { name: 'Nouvelle note', _createdAt: dayjs() }
    note._author = Auth.user
    note.type = 'new'
    note.content = []

    setNewNote(note)

    setNotes(() => ({
      data: [{ ...note }, ...prevNotes.data || []],
      $pagination: {
        ...prevNotes.$pagination,
        totalItems: (prevNotes.$pagination?.totalItems || 0) + 1,
      },
    }))
    setContent([])
    setNoteSelected({ ...note, _template: { name: 'Nouvelle note', _createdAt: dayjs() }})
  }

  return (
    <>
      <div
        onClick={(e) => {
          e?.stopPropagation?.()
          setVisible(true)
        }}
      >
        {children}
      </div>
      <Context.Provider
        value={{
          visible,
          setVisible,
          loading: loading || loadingNotes,
          setLoading,
          companyName,
          companyId,
          missionId,
          mode,
          notes,
          setNotes,
          setMode,
          newNote,
          setNewNote,
          content,
          setContent,
          noteSelected,
          setNoteSelected,
          params,
          setParams,
          noteToModify,
          setNoteToModify,
          newComment,
          setNewComment,
          mentionUsers,
          emptyEditorContent,
          templates,
          setAutoScroll,
          getNotes,
          getNote,
          updateNote,
          setVisibleDelete,
          forcedMissionId,
          setForcedMissionId,
          defaultSelected,
        }}
      >
        <Drawer
          width='30%'
          open={visible}
          onClose={() => {
            onClose?.()
            getNotes()
            reload?.()
            setVisible(false)
          }}
          placement={mode}
          mask={false}
          title={(
            <div className='space-y-2'>
              <div className='flex items-center justify-between'>
                <div className='flex space-x-4 items-center'>
                  <div className='font-bold'>
                    Prise de notes
                  </div>
                  {missionId && (
                    <Dropdown
                      menu={{
                        items: [{
                          key: 'company',
                          label: 'Société',
                          onClick: () => setParams({ ...params, $filter: { ...params.$filter, _mission: undefined }}),
                        }, {
                          key: 'mission',
                          label: 'Mission',
                          onClick: () => setParams({ ...params, $filter: { ...params.$filter, _mission: missionId }}),
                        }],
                      }}
                    >
                      <div className='text-sm font-normal flex space-x-2 items-center'>
                        <div>{params.$filter?._mission ? 'Mission' : 'Société'}</div>
                        <DownOutlined />
                      </div>
                    </Dropdown>
                  )}
                </div>
                <div>{companyName}</div>
                <ModeDropdown mode={mode} setMode={setMode} />
              </div>
              <div className='flex items-center justify-between'>
                <Button
                  type="link" onClick={() => window.open('/clients/notes')}
                  icon={<ArrowRightSquare className='h-3 w-3' />}
                >
              Accéder à toutes les notes
                </Button>
                <Button
                  icon={<PlusCircleOutlined/>}
                  disabled={!!Object.keys(newNote)?.length && notes.data?.find((n) => n?.type === 'new')}
                  onClick={() => initNewNote(notes)}
                >
                  Nouvelle note
                </Button>
              </div>
            </div>
          )}
          styles={{
            footer: {
              padding: '0.75rem 1rem !important',
            },
          }}
          footer={!isList && noteSelected && noteSelected.type !== 'new' && !noteToModify && (
            <div className='w-full flex items-center shadow-t space-x-1'>
              <div className='w-8'>
                <Avatar src={Auth.user.picture} />
              </div>
              <div
                className={classNames(
                  'p-1 bg-bg-container rounded',
                  'hover:border-primary-border-hover',
                  'focus-within:border-primary-border focus-within:ring-2 focus-within:ring-blue-50',
                  'w-full max-h-[200px] overflow-auto',
                )}>
                {mentionUsers?.length && (
                  <Editor
                    mentions={mentionUsers}
                    hideButtons
                    className='rounded-3xl px-4'
                    autoFocus={false}
                    value={newComment}
                    onChange={(value) => {
                      if (JSON.stringify(newComment) !== JSON.stringify(value)) {
                        setNewComment(value)
                      }
                    }}
                  />
                )}
              </div>
              <Button
                shape="circle"
                type="primary"
                disabled={JSON.stringify(newComment) === JSON.stringify(emptyEditorContent)}
                onClick={() => createComment()}
              >
                <SendOutlined />
              </Button>
            </div>
          )}
        >
          <Spin spinning={loading || loadingNotes} className='h-full'>
            {isList
              ? <NotesList />
              : <Note />
            }
          </Spin>
        </Drawer>
        <Modal
          open={visibleDelete}
          onCancel={() => setVisibleDelete(false)}
          title="Supprimer la note"
          okText='Supprimer la note'
          onOk={() => deleteNote()}
          okButtonProps={{ style: { backgroundColor: 'red' }}}
        >
          Êtes-vous sûr de vouloir supprimer ce commentaire ? Le supprimer entrainera la suppression de la note et de tous les commentaires associés.
        </Modal>
      </Context.Provider>
    </>
  )
}

Notes.propTypes = {
  children: PropTypes.element,
  missionId: PropTypes.string,
  companyId: PropTypes.string.isRequired,
  defaultSelected: PropTypes.string,
  reload: PropTypes.func,
  onClose: PropTypes.func,
  noteId: PropTypes.string,
  defaultVisible: PropTypes.bool,
}

export default Notes
